import { Box, Button, Container, Grid, Typography } from "@mui/material"
import { CarrouselSobreNos } from "../carrouselsobrenos/CarrouselSobreNos"
import { motion } from "framer-motion";
import { CarrouselLivingLean } from "../carrouselLivingLean/CarrouselLivingLean";

export const SobreNos = () => {

    return (

        

        <Box  sx={{ bgcolor: '#000', py: 5 }}>
            <Container>
            <Grid container >
                    <Grid item xs={12} md={6} sx={{ mt: 5, ml: 'auto', order: { xs: 2, md: 1 } }}>
                        <CarrouselSobreNos />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ mt: { xs: 0, md: 5 },order: { xs: 2, lg: 1 } }}>
                    <Box sx={{display:'flex',flexDirection:'column',mt:2}}>
                        <Box sx={{display:'flex',alignItems:'center',gap:2}}>
                    <Box sx={{width:{xs:'25px',sm:'40px'},height:{xs:'25px',sm:'40px'}}}>
                        <img src="images/iconsheader/indeias.png" style={{ width: '100%', height: '100%' }} />
                        </Box>
                        <Typography sx={{ color: '#5AFF43', fontSize: { xs: '32px', sm: '56px' }, fontWeight: 'bold', lineHeight: { xs: '32px', sm: '56px' } }}>NÓS SOMOS A </Typography>
                        
                        </Box>
                        <Typography sx={{fontSize:{xs:'32px',sm:'56px'},fontWeight:'bold',color:'#fff',lineHeight:{xs:'32px',sm:'56px'}}}>INDEIAS<motion.span
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 0.1 }}
                                style={{color:'#5AFF43'}}
                                transition={{
                                    repeat: Infinity, // Repete a animação infinitamente
                                    repeatType: 'reverse', // Inverte a animação entre "initial" e "animate"
                                    duration: 0.5, // Duração de cada piscada
                                }}
                            >
                                _
                            </motion.span></Typography>
                    </Box>
                    <Box sx={{ mt: { xs: 2, lg: 2 } }}>
                        <Typography variant="roboto" sx={{ color:'#fff',fontSize: {xs:'16px',sm:'24px'},  textAlign: 'end',lineHeight:{xs:'16px',sm:'24px'},fontWeight:'light' }}>Desenvolvemos soluções inovadoras, abragemtes e simplificadas, atendendo os mais diversos segmentos, visando<span style={{color:'#5AFF43',fontWeight:'bold'}}> entrega de serviços e produtos dinâmicos e customizados.</span></Typography>
                        </Box>
                   
                    </Grid>
                </Grid>


                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', mt: 5, }}>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: { xs: 'end', md: 'center' } }}>


                        <Typography sx={{ fontSize: { xs: '32px', sm: '56px' }, color: '#fff', fontWeight: 'bold' }}>LIVING <span style={{ color: '#5AFF43' }}>LEAN</span><motion.span
                            initial={{ opacity: 1 }}
                            animate={{ opacity: 0.1 }}
                            transition={{
                                repeat: Infinity, // Repete a animação infinitamente
                                repeatType: 'reverse', // Inverte a animação entre "initial" e "animate"
                                duration: 0.5, // Duração de cada piscada
                            }}
                        >
                            _
                        </motion.span></Typography>
                        <Box sx={{ mt: 0.5, width: { xs: '100%', md: '58%' }, textAlign: 'end' }}>
                            <Typography variant="roboto" sx={{ color:'#fff',fontSize: {xs:'16px',sm:'24px'},lineHeight:{xs:'16px',sm:'24px'},fontWeight:'light' }} >A abordagem Lean é a <span style={{ fontWeight: 'bold', color: '#5AFF43' }}>base dos projetos de transformação da Indeias</span>. Otimizamos processos e entregamos valor contínuo, assegurando eficiência e impacto em cada etapa da jornada</Typography>
                        </Box>

                    </Box>


                </Box>


                    <CarrouselLivingLean/>
                                        




            </Container>
        </Box>
     
    )
}