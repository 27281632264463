import { createTheme } from "@mui/material";
export const ThemeLight = createTheme({
  palette: {
    primary: {
      main: '#5AFF43',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Roboto Mono, monospace', 
    roboto: {
      fontFamily: 'Roboto',
    },
    body1: {
      color: '#000', // Defina a cor do texto como preto
    },
  },
});
