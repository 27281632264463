import { Box, Button, Container, Grid, Typography } from "@mui/material"



export const NewSletter =()=>{
    return(
        <Box sx={{bgcolor:'#5AFF43',py:5}}>

            <Container>

           
        <Grid container>

        <Grid item xs={12} lg={6}>
        <Box sx={{display:'flex',width:'100%',alignItems:'center',justifyContent:{xs:'center',lg:'start'}}}>
        <Box sx={{width:{xs:'300px',lg:'450px'}}}>
        <img src="images/newsletter/logonewsletter.png" style={{width:'100%',height:'100%'}}/>
        
        </Box>
        </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
        <Box sx={{display:'flex',flexDirection:'column',textAlign:{xs:'center',lg:'end'},alignItems:{xs:'center',lg:'end'},gap:3,mt:1}}>
        <Typography variant="roboto" sx={{fontSize:{xs:'16px',sm:'24px'},lineHeight:{xs:'16px',sm:'24px'},fontWeight:'light'}}>Fique por dentro das notícias sobre<br/>nossas áreas que atuamos e outras coisas a mais.<br/><span style={{fontWeight:'bold'}}>Cadastre-se. É grátis!</span></Typography>
        <Button variant="contained" href="https://indeias.substack.com" target="_blank" sx={{boxShadow:0,bgcolor:'#000',color:'#fff',fontSize:{xs:"14px",sm:'18px'},fontWeight:'bold',borderRadius:'0px',width:'270px',height:'40px'}}>CLIQUE PARA ASSINAR</Button>
        </Box> 
        </Grid>
        </Grid>
        </Container>
        </Box>


    )
}