
import { Box, Typography, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useTheme } from '@emotion/react';

export const CarrouselClientes = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (

    <Swiper

      breakpoints={{
        0: {
          slidesPerView: 2,
          spaceBetween: 20


        },
        768: {
          slidesPerView: 3
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 100,
        }
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}


    >
      {/* Desktop Images */}
      {!isMobile && (
        <>
          <SwiperSlide>

            <Box sx={{ width: '200px', height: '150px' }}>
              <img
                src="images/clientes/link1.png"
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
          </SwiperSlide>
          <SwiperSlide>

            <Box sx={{ width: '200px', height: '150px' }}>
              <img
                src="images/clientes/link2.png"
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
          </SwiperSlide>
          <SwiperSlide>

            <Box sx={{ width: '200px', height: '150px' }}>
              <img
                src="images/clientes/link3.png"
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
          </SwiperSlide>
          <SwiperSlide>

            <Box sx={{ width: '200px', height: '150px' }}>
              <img
                src="images/clientes/link4.png"
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
          </SwiperSlide>
          <SwiperSlide>

            <Box sx={{ width: '200px', height: '150px' }}>
              <img
                src="images/clientes/link5.png"
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
          </SwiperSlide>

          {/* Add more slides as needed */}
        </>
      )}

      {/* Mobile Images */}
      {isMobile && (
        <>
          <SwiperSlide>

            <Box sx={{ width: '130px', height: '100px' }}>
              <img
                src="images/clientes/link1.png"
                style={{ width: '100%', height: '100%' }}

              />
            </Box>
          </SwiperSlide>
          <SwiperSlide>

            <Box sx={{ width: '130px', height: '100px' }}>


              <img
                src="images/clientes/link2.png"
                style={{ width: '100%', height: '100%' }}

              />
            </Box>
          </SwiperSlide>
          <SwiperSlide>

            <Box sx={{ width: '130px', height: '100px' }}>


              <img
                src="images/clientes/link3.png"
                style={{ width: '100%', height: '100%' }}

              />
            </Box>
          </SwiperSlide>
          <SwiperSlide>

            <Box sx={{ width: '130px', height: '100px' }}>


              <img
                src="images/clientes/link4.png"
                style={{ width: '100%', height: '100%' }}

              />
            </Box>

          </SwiperSlide>
          <SwiperSlide>

            <Box sx={{ width: '130px', height: '100px' }}>


              <img
                src="images/clientes/link5.png"
                style={{ width: '100%', height: '100%' }}

              />
            </Box>

          </SwiperSlide>

        </>
      )}
    </Swiper>





  );
};