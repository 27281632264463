import { Box, Button, Container, Typography } from "@mui/material"
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { useState } from "react";
import { motion } from "framer-motion";
export const Estatistica = () => {

    const [counting, setCounting] = useState(false);

    const handleVisibilityChange = (isVisible) => {
        if (isVisible) {
            setCounting(true);
        }
    };
    return (
        <VisibilitySensor onChange={handleVisibilityChange} partialVisibility>

            {({ isVisible }) => (
                <Box sx={{
                    backgroundImage: 'url("/images/estatisticas/bgestatistica.png")',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'

                }}>
                    <Container>

                        <Box sx={{ display: 'flex', flexDirection: 'column', py: 5, gap: 3, alignItems: 'center' }}>
                            <Typography sx={{ fontSize: { xs: '32px', sm: '56px', textAlign: 'center' }, fontWeight: 'bold', lineHeight: { xs: '32px', sm: '56px' }, color: '#fff' }}>O IMPOSSÍVEL É SÓ QUESTÃO DE <span style={{ color: '#5AFF43' }}>OPINIÃO<motion.span
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 0.1 }}
                                style={{color:'#5AFF43'}}
                                transition={{
                                    repeat: Infinity, // Repete a animação infinitamente
                                    repeatType: 'reverse', // Inverte a animação entre "initial" e "animate"
                                    duration: 0.5, // Duração de cada piscada
                                }}
                            >
                                _
                            </motion.span></span></Typography>
                            <Typography variant="roboto" sx={{ fontSize: { xs: '16px', sm: '24px' }, color: '#fff', textAlign: 'center', lineHeight: { xs: '16px', sm: '28px' }, fontWeight: 'light' }}>Somos resilientes e utilizamos de <span style={{ fontWeight: 'bold', color: '#5AFF43' }}>ousadia e estratégia</span> para entregar o melhor produto ou serviço!</Typography>


                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'unset' }, alignItems: 'center', mt: 5, gap: 5 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box sx={{ width: '48px', height: '48px' }}>
                                        <img src="images/estatisticas/icon1.png" style={{ width: '100%', height: '100%' }} />
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '48px', color: '#fff' }}>+</Typography>
                                        <CountUp start={0} end={90} duration={8} style={{ fontSize: '48px', fontWeight: 'bold', color: '#fff' }} formattingFn={(value) => value.toFixed(0)} />
                                    </Box>
                                    <Typography sx={{ fontSize: { xs: '16px', sm:'17px' }, height: { lg: '100px' }, color: '#fff', textAlign: 'center', lineHeight: { xs: '16px', lg: '28px' }, fontWeight: 'light' }}>Projetos de transformação digital realizados</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box sx={{ width: '65px', height: '48px' }}>
                                        <img src="images/estatisticas/icon2.png" style={{ width: '100%', height: '100%' }} />
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '48px', color: '#fff' }}>+</Typography>
                                        <CountUp start={0} end={35} duration={8} style={{ fontSize: '48px', fontWeight: 'bold', color: '#fff' }} formattingFn={(value) => value.toFixed(0)} />
                                    </Box>
                                    <Typography sx={{ fontSize: { xs: '16px', sm:'17px' }, height: { lg: '100px' }, color: '#fff', textAlign: 'center', lineHeight: { xs: '16px', lg: '28px' }, fontWeight: 'light' }}>Clientes satisfeitos com o que fizemos</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box sx={{ width: '79px', height: '48px' }}>
                                        <img src="images/estatisticas/icon3.png" style={{ width: '100%', height: '100%' }} />
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '48px', color: '#fff' }}>+</Typography>
                                        <CountUp start={0} end={100000} duration={8} style={{ fontSize: '48px', fontWeight: 'bold', color: '#fff' }} formattingFn={(value) => value.toFixed(0)} />
                                    </Box>
                                    <Typography sx={{ fontSize: { xs: '16px', sm:'17px' }, height: { lg: '100px' }, color: '#fff', textAlign: 'center', lineHeight: { xs: '16px', lg: '28px' }, fontWeight: 'light' }}>Linhas de códigos,<br />scripts, fórmulas e consultas digitadas</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box sx={{ width: '48px', height: '48px' }}>
                                        <img src="images/estatisticas/icon4.png" style={{ width: '100%', height: '100%' }} />
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '48px', color: '#fff' }}>+</Typography>
                                        <CountUp start={0} end={99} duration={8} style={{ fontSize: '48px', fontWeight: 'bold', color: '#fff' }} formattingFn={(value) => value.toFixed(0)} />
                                    </Box>
                                    <Typography sx={{ fontSize: { xs: '16px', sm:'17px' }, height: { lg: '100px' }, color: '#fff', textAlign: 'center', lineHeight: { xs: '16px', lg: '28px' }, fontWeight: 'light' }}>Horas simultâneas de cafés tomados ♥</Typography>
                                </Box>
                            </Box>
                            <ScrollLink
                                to="vamos conversar"
                                spy={true}
                                smooth={true}
                                offset={-50}
                                duration={800}
                            >
                                <Button variant="outlined" sx={{
                                    '&:hover': {
                                        bgcolor:'#000'
                                    },
                                    color: '#000',
                                    width: '235px',
                                    height: '45px',
                                    fontSize: {xs:"14px",sm:'18px'},
                                    fontWeight: 'bold',
                                    boxShadow: '0px',
                                    color: '#5AFF43',
                                    borderRadius: '0px', width: { xs: '100%', lg: '350px' }
                                }}>ENTÃO, VAMOS FECHAR?</Button>
                            </ScrollLink>

                        </Box>
                    </Container>
                </Box>
            )}
        </VisibilitySensor>
    )
}