import { Element } from "react-scroll"
import { Cases } from "../../components/cases/Cases"
import { Confia } from "../../components/confia/Confia"
import { Estatistica } from "../../components/estatistica/Estatistica"
import { Footer } from "../../components/footer/Footer"
import { Frases } from "../../components/frases/Frases"
import { Header } from "../../components/header/Header"
import { NavBar } from "../../components/navbar/NavBar"
import { NossosProdutos } from "../../components/nossosprodutos/NossosProdutos"
import { SobreNos } from "../../components/sobrenos/SobreNos"
import { Solucoes } from "../../components/solucoes/Solucoes"
import { NewSletter } from "../../components/newsletter/NewSletter"



export const Home = () => {

    return (
      <>
      
        <NavBar />
        <Element name="home">
        <Header />
        </Element>
      
        <Frases />
        <Element name="quem somos">
        <SobreNos/>
        </Element>
        <Element name="soluções">
        <Solucoes/>
        </Element>
      <Element name="produtos">
      <NossosProdutos/>
      </Element>
      <Element name="cases">
      <Cases/>
      </Element>
       <Element name="clientes">
       <Confia/>
       </Element>
        <Estatistica />
        <NewSletter/>
        <Element name="vamos conversar">
        <Footer />
        </Element>
       
      </>
    );
  };
  