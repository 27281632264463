import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Button } from '@mui/material';
import { motion } from "framer-motion";
import { AnimacaoIcons } from '../animacaoicons/AnimacaoIcons';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import './Gradiente.css'
export const Header = () => {

  
  
  const frases = [
    'seu negócio',
    'seu marketing',
    'seus dados',
    'seu site',
    'sua comunicação',
    'seus sistemas',
    'sua gestão',
    'sua marca',
    'suas experiências',
    'sua vida',
  ];

  const [fraseIndex, setFraseIndex] = useState(0);
  const [textoAtual, setTextoAtual] = useState('');
  const [apagando, setApagando] = useState(false);

  useEffect(() => {
    let timer;

    if (!apagando) {
      if (textoAtual.length < frases[fraseIndex].length) {
        timer = setTimeout(() => {
          setTextoAtual((prevTexto) => prevTexto + frases[fraseIndex][prevTexto.length]);
        }, 100); // Velocidade de digitação (ajuste conforme necessário)
      } else {
        setTimeout(() => {
          setApagando(true);
        }, 4000); // Pausa de 2 segundos antes de começar a apagar
      }
    } else {
      if (textoAtual.length > 0) {
        timer = setTimeout(() => {
          setTextoAtual((prevTexto) => prevTexto.slice(0, -1));
        }, 50); // Velocidade de apagamento (ajuste conforme necessário)
      } else {
        setApagando(false);
        setFraseIndex((prevIndex) => (prevIndex + 1) % frases.length); // Alternar para a próxima frase
        timer = setTimeout(() => {
          setTextoAtual(''); // Limpar o texto após a pausa
        }, 1000); // Pausa após apagar a frase (ajuste conforme necessário)
      }
    }

    return () => clearTimeout(timer);
  }, [fraseIndex, apagando, textoAtual, frases]);

  return (

    <Box id="boxgradiente">

    <Box
      sx={{
        backgroundImage: 'url("/images/background_menu_desktop.png")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        
        height:{xs:'530px',sm:'600px',md:'700px',lg:'750px'},
        backgroundPosition: { xs: 'bottom' },
      }}
    >
      <Container>
        <Grid container >
          <Grid item xs={12} sm={12} md={6} sx={{ my:{md:10},mt:{xs:10,sm:15,md:15,lg:15} }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ height:{xs:'180px',lg:'400px'}}}>
                <Typography
                  sx={{
                    fontSize:{xs:'28px',sm:'48px'},
                    lineHeight:{xs:'28px',sm:'48px'},
                    mt: 2,
                    color:'#fff',
                    fontWeight: 'regular',
                  }}
                >
                  Usamos ideias<br />para transformar<br />{' '}
                  <span style={{ fontWeight: '600',color:'#5AFF43'}}>{textoAtual}<motion.span
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{
          repeat: Infinity, // Repete a animação infinitamente
          repeatType: 'loop', // Inverte a animação entre "initial" e "animate"
          duration: 0.8, // Duração de cada piscada
        }}
      >|
      </motion.span></span>
                </Typography>
                <Box sx={{mt:5,width:'80%'}}>
                <Typography variant='roboto' sx={{color:'#fff',lineHeight:{xs:'16px',sm:'24px'},fontSize:{xs:'16px',sm:'24px'},fontWeight:'light'}}><span style={{fontWeight:'bold'}}>Maximizamos seu desempenho com nossa abordagem 360º</span>, desde a criação de sistemas e sites até o desenvolvimento de aplicativos, e-commerce, soluções de delivery, gestão de dados e branding, com foco em tornar o complexo, simples.</Typography>
                </Box>
                <ScrollLink
                   to="vamos conversar"
                   spy={true}
                   smooth={true}
                   duration={800}
                >
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: '0px',
                   
                    width: { xs: '235px',sm:'235px', md: '235px',lg:'235px' },
                    height: {xs:'47px',md:'47px',lg:'47px'},
                    fontSize: {xs:"14px",sm:'18px'},
                    fontWeight: 'bold',
                  
                    mt: { xs: 3, md: 3 },
                  }}
                >
                 VAMOS CONVERSAR?
                </Button>
                </ScrollLink>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ ml:'auto',mt: {xs:0,md:11,sm:3}}}>
           <AnimacaoIcons/>
          </Grid>
        </Grid>
      </Container>
    </Box>
    </Box>
  );
};








