
import { Box, Typography, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useTheme } from '@emotion/react';
import { useEffect } from 'react';

export const CarrouselSobreNos = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  useEffect(() => {
    // Carregar o script do Wistia
    const script1 = document.createElement('script');
    script1.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script1.async = true;
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.src = 'https://fast.wistia.com/embed/medias/qc23jagn1d.jsonp';
    script2.async = true;
    document.body.appendChild(script2);

    return () => {
      // Limpar os scripts quando o componente for desmontado
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return (

    <Swiper
      slidesPerView={1}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
    >
      {/* Desktop Images */}
      {!isMobile && (
        <>
          <SwiperSlide>
        <Box sx={{width:{sm:'100%',md:'468px'},height:{sm:'100%',md:'266px'}}}>
         <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
      <div className="wistia_responsive_wrapper" style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}>
        <div className="wistia_embed wistia_async_qc23jagn1d seo=false videoFoam=true" style={{ height: '100%', position: 'relative', width: '100%' }}>
          <div className="wistia_swatch" style={{ height: '100%', left: 0, opacity: 0, overflow: 'hidden', position: 'absolute', top: 0, transition: 'opacity 200ms', width: '100%' }}>
            <img src="https://fast.wistia.com/embed/medias/qc23jagn1d/swatch" style={{ filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' }} alt="" aria-hidden="true" onLoad={(e) => { e.target.parentNode.style.opacity = 1; }} />
          </div>
        </div>
      </div>
    </div>
    </Box>


          </SwiperSlide>
          {/* Add more slides as needed */}
        </>
      )}

      {/* Mobile Images */}
      {isMobile && (
        <>
          <SwiperSlide>

            <Box sx={{width:'100%',height:'100%'}}>
            <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
      <div className="wistia_responsive_wrapper" style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}>
        <div className="wistia_embed wistia_async_qc23jagn1d seo=false videoFoam=true" style={{ height: '100%', position: 'relative', width: '100%' }}>
          <div className="wistia_swatch" style={{ height: '100%', left: 0, opacity: 0, overflow: 'hidden', position: 'absolute', top: 0, transition: 'opacity 200ms', width: '100%' }}>
            <img src="https://fast.wistia.com/embed/medias/qc23jagn1d/swatch" style={{ filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' }} alt="" aria-hidden="true" onLoad={(e) => { e.target.parentNode.style.opacity = 1; }} />
          </div>
        </div>
      </div>
    </div>
            </Box>
          </SwiperSlide>

        </>
      )}
    </Swiper>





  );
};