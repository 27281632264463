import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/body/Home";
import { AppThemeProvider } from "./theme/ThemeProvider";

function App() {
  return (
    <AppThemeProvider>
    <BrowserRouter >
    <Routes>
      <Route path="/" element={<Home/>} />
    </Routes>
  </BrowserRouter>
  </AppThemeProvider>
  );
}

export default App;
