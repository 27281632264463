import { TextField, Box, Typography, Button, Snackbar } from "@mui/material"
import { useState } from "react";

export const FormConversar = () => {

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    cnpj: "",
    telefone: "",
    message: "",
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    try {
      const response = await fetch("https://formspree.io/f/moqorlkk", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        // Limpe os campos do formulário
        setFormValues({
          name: "",
          email: "",
          cnpj: "",
          telefone: "",
          message: "",
        });

        // Exiba o snackbar de sucesso
        setSnackbarOpen(true);
      } else {
        // Lide com erros aqui (por exemplo, mostre uma mensagem de erro).
        console.error("Falha no envio do formulário.");
      }
    } catch (error) {
      // Lide com erros de rede ou outras exceções aqui.
      console.error("Ocorreu um erro:", error);
    }
  };
  return (
    <Box component="form" onSubmit={handleSubmit}
      sx={{ display: 'flex', gap: 3, flexDirection: 'column' }}>
      {/* Seu código para os campos de entrada continua aqui */}

      <TextField
        label='Digite seu nome e sobrenome'
        focused
        type="text"
        name="name"
        value={formValues.name}
        onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
        sx={{
          color: '#fff', width: '100%',

          '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
            color: '#fff', // Cor do texto quando o campo não está em foco
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
            borderRadius: '0px',
            color: '#fff' // Substitua 'green' pela cor desejada
          },
        }}
      />


      <TextField
        label='Digite seu e-mail'
        type="email"
        name="email"
        focused
        value={formValues.email}
        onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
        sx={{
          color: '#fff', width: '100%',

          '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
            color: '#fff', // Cor do texto quando o campo não está em foco
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
            borderRadius: '0px',
            color: '#fff' // Substitua 'green' pela cor desejada
          },
        }}
      />

      <TextField
        label='Digite o nome da empresa ou razão social'
        type="text"
        name='cnpj'
        focused
        value={formValues.cnpj}
        onChange={(e) => setFormValues({ ...formValues, cnpj: e.target.value })}
        sx={{
          color: '#fff', width: '100%',

          '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
            color: '#fff', // Cor do texto quando o campo não está em foco
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
            borderRadius: '0px',
            color: '#fff',

          },
        }}
      />

      <TextField
        label='Digite seu telefone ou WhatsApp/Telegram'
        type="text"
        name="telefone"
        focused
        value={formValues.telefone}
        onChange={(e) => setFormValues({ ...formValues, telefone: e.target.value })}
        sx={{
          color: '#fff', width: '100%',

          '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
            color: '#fff', // Cor do texto quando o campo não está em foco
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
            borderRadius: '0px',
            color: '#fff' // Substitua 'green' pela cor desejada
          },
        }}
      />

      <TextField
        label='Conte mais para a gente como podemos te ajudar'
        name="message"
        multiline
        rows={4}
        focused
        value={formValues.message}
        onChange={(e) => setFormValues({ ...formValues, message: e.target.value })}
        sx={{
          color: '#fff', width: '100%',

          '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
            color: '#fff', // Cor do texto quando o campo não está em foco
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
            borderRadius: '0px',
            color: '#fff' // Substitua 'green' pela cor desejada
          },
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, gap: 5 }}>

        <Box sx={{ display: 'flex', justifyContent: 'end', mb: 1 }}>
          <Button variant="contained" type="submit" sx={{ color: '#000', fontWeight: 'bold', fontSize: {xs:"14px",sm:'18px'}, borderRadius: '0px' }}>ENVIAR IDEIA</Button>
          <Button variant="contained" type="reset" sx={{ ml: 2, color: '#000', fontWeight: 'bold', fontSize: {xs:"14px",sm:'18px'}, bgcolor: '#fff', borderRadius: '0px' }}>Limpar</Button>
        </Box>

      </Box>
      <Box>
        <Typography sx={{ fontSize: {xs:'12px',sm:'14px'}, lineHeight: '14px', color: '#fff' }}>Suas informações de contato não serão usadas para enviar SPAM.<br />
          Também detestamos SPAM.</Typography>
      </Box>
      <Typography variant="roboto" sx={{ display:{xs:'block',md:'none'},fontWeight:'light',fontSize: {xs:'16px',sm:'24px'}, lineHeight: {xs:'16px',sm:'24px'}, color: '#fff' }}>Quer falar com a gente por outros meios?<br />
          Sem problemas!</Typography>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="Email enviado com sucesso!"
      />
    </Box>
  )
}