import { Box, Container, Grid, Link, TextField, Typography } from "@mui/material"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faWhatsapp, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FormConversar } from "../formconversar/FormConversar";
import { motion } from "framer-motion";

export const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (

        <Box sx={{ bgcolor: '#000', py: 5 }}>
            <Container>


                <Grid container>

                    <Grid item xs={12} lg={6} sx={{ order: { xs: 2, lg: 2 } }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography sx={{  display:{xs:'none',md:'block'},fontSize: { xs: '32px', lg: '56px' }, fontWeight: 'bold', lineHeight: { xs: '32px', lg: '56px' }, color: '#5AFF43' }}>BORA<span style={{ color: '#fff' }}> CONVERSAR</span><motion.span
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 0.1 }}
                                style={{color:'#5AFF43'}}
                                transition={{
                                    repeat: Infinity, // Repete a animação infinitamente
                                    repeatType: 'reverse', // Inverte a animação entre "initial" e "animate"
                                    duration: 0.5, // Duração de cada piscada
                                }}
                            >
                                ?
                            </motion.span></Typography>
                        <Typography variant="roboto" sx={{ display:{xs:'none',md:'block'},fontWeight:'light',fontSize: '24px', lineHeight: '24px', color: '#fff' }}>Quer falar com a gente por outros meios?<br/>
          Sem problemas!</Typography>
                            <Link variant="roboto" href="mailto:contato@indeias.com.br" sx={{ textDecoration:'none',fontWeight: 'bold', color: '#5AFF43', fontSize: {xs:'18px',sm:'28px'}, mt: 2 }}>contato@indeias.com.br</Link>
        
                            <Link href="https://wa.me/5511945715167" target="_blank" sx={{ textDecoration: 'none' }}>
                                <Typography variant="roboto" sx={{ cursor: 'pointer', fontWeight: 'bold', color: '#5AFF43', fontSize: {xs:'29px',sm:'39px'} }}> <FontAwesomeIcon icon={faWhatsapp} />   (11) 94571-5167</Typography>
                            </Link>

                            <Typography sx={{ mt:2,color: '#fff', fontSize: { xs: '14px', sm: '24px' }, lineHeight: { xs: '14px', sm: '24px' }, fontWeight: 'light' }}>Somos <span style={{fontWeight:'bold'}}>remote anywhere.</span> <br />
                                Atendemos qualquer lugar do<br />Brasil ou do Mundo,<br />
                                <span style={{ fontWeight: 'bold' }}>onde você estiver.</span></Typography>
                            <Box sx={{ width: '208px', height: '33px', mt: 5 }}>
                                <img src="/images/logo_indeias.png" />
                            </Box>
                            <Typography variant="roboto" sx={{ color: '#5AFF43', fontSize: { xs: '16px', sm: '24px' }, lineHeight: { xs: '16px', sm: '28px' }, fontWeight: 'bold', mt: 1 }}>Siga a gente em nossas redes</Typography>
                            <Box sx={{ display: 'flex', gap: 2.8, }}>
                                <Link href="https://www.facebook.com/indeias/" target='_blank' sx={{ fontSize: { xs: '25px', sm: '45px' } }}>
                                    <FontAwesomeIcon icon={faFacebook} style={{ color: '#fff' }} />
                                </Link>
                                <Link href="https://www.linkedin.com/company/indeias" target='_blank' sx={{ fontSize: { xs: '25px', sm: '45px' } }}>
                                    <FontAwesomeIcon icon={faLinkedin} style={{ color: '#fff' }} />
                                </Link>
                                <Link href="https://instagram.com/indeiasoficial/" target='_blank' sx={{ fontSize: { xs: '25px', sm: '45px' } }}>
                                    <FontAwesomeIcon icon={faInstagram} style={{ color: '#fff' }} />
                                </Link>
                                <Link href="https://www.youtube.com/@indeias" target='_blank' sx={{ fontSize: { xs: '25px', sm: '45px' } }}>
                                    <FontAwesomeIcon icon={faYoutube} style={{ color: '#fff' }} />
                                </Link>
                                <Link href="https://bsky.app/profile/indeias.com.br" target="_blank" sx={{ fontSize: { xs: '25px', sm: '45px' } }}>
                                    <Box sx={{ width: { xs: '25px', sm: '45px' }, height: { xs: '25px', sm: '40px' } }}>
                                        <img src="images/footer/bsky.png" style={{ width: '100%', height: '100%' }} />
                                    </Box>
                                </Link>

                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{ order: { xs: 1, lg: 2 } }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'start', lg: 'end' }, gap: 1, mt: 2 }}>
                            <Typography sx={{ display:{xs:'block',md:'none'},fontSize: { xs: '32px', sm: '56px' }, fontWeight: 'bold', lineHeight: { xs: '32px', sm: '56px' }, color: '#5AFF43' }}>BORA <span style={{ color: '#fff' }}>CONVERSAR</span><motion.span
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 0.1 }}
                                style={{color:'#5AFF43'}}
                                transition={{
                                    repeat: Infinity, // Repete a animação infinitamente
                                    repeatType: 'reverse', // Inverte a animação entre "initial" e "animate"
                                    duration: 0.5, // Duração de cada piscada
                                }}
                            >
                                ?
                            </motion.span></Typography>
                            <Typography variant="roboto" sx={{ textAlign: { xs: 'start', lg: 'end' }, fontSize: { xs: '16px', sm: '24px' }, lineHeight: { xs: '16px', sm: '24px' }, fontWeight: 'Light', color: '#fff' }}>Sua transformação digital é nossa prioridade.<br />
                                Estamos prontos para juntos tirar sua ideia do papel.
                                <br /><span style={{ fontWeight: 'bold', color: '#5AFF43' }}>Pronto para começar?</span></Typography>
                        </Box>
                        <Box sx={{ mt: 5 }}>
                            <FormConversar />
                            
                        </Box>
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5, textAlign: 'center' }}>
                    <Typography sx={{ display: { xs: 'none', sm: 'flex' }, color: '#fff', fontSize: '16px' }}>© Copyright {currentYear} - Indeias | Criado na Amazônia - Brasil, à base de ❤ e ☕</Typography>
                    <Typography sx={{ display: { xs: 'flex', sm: 'none' }, color: '#fff', fontSize: '11px' }}>© Copyright {currentYear} - Indeias
                        <br/>Criado na Amazônia - Brasil, à base de ❤ e ☕</Typography>
                </Box>

            </Container>
        </Box>
    )
}