import { ThemeProvider } from "@emotion/react";
import { createContext } from "react";
import {CssBaseline } from "@mui/material";
import { ThemeLight } from "./ThemeLight";





const ThemeContext = createContext({})


export const AppThemeProvider =({children})=>{
    return(
        <ThemeContext.Provider value={ThemeContext}>
            <ThemeProvider theme={ThemeLight}>
            <CssBaseline/>
            
            {children}
          
            </ThemeProvider>
        </ThemeContext.Provider>
    )
}