import { Box, Button, Container, Grid, Typography } from "@mui/material"

import { CarrouselCases } from "../carrouselcases/CarrouselCases"
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

export const Cases = () => {
    return (
        <>
            <Box sx={{ bgcolor: '#5AFF43', py: 5 }}>


                <Container>

                    <Grid container spacing={2} >
                        <Grid item xs={12} sx={{ mt: 2, order: { xs: 1, md: 1 } }} md={4}>

                            <Box sx={{ display: 'flex', alignItems: { xs: 'start' }, textAlign: 'start', flexDirection: 'column' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Box sx={{ width: { xs: '25px', sm: '40px' }, height: { xs: '25px', sm: '40px' } }}>
                                        <img src="images/iconindeiasblack.png" style={{ width: '100%', height: '100%' }} />
                                    </Box>

                                    <Typography sx={{ fontSize: { xs: '32px', sm: '56px' }, fontWeight: 'bold', lineHeight: { xs: '32px', sm: '56px' } }}>NOSSOS</Typography>
                                </Box>

                                <Typography sx={{ fontSize: { xs: '32px', sm: '56px' }, fontWeight: 'bold', lineHeight: { xs: '32px', sm: '56px' } }}>CASES</Typography>

                                <Typography variant="roboto" sx={{ mt: 2, width: "83%", fontSize: { sm: '24px', xs: '16px' }, lineHeight: { sm: '24px', xs: '16px' }, fontWeight: 'light' }}>Conheça os projetos e clientes que se surpreederam com  <span style={{fontWeight:'bold'}}>nossos serviços!</span></Typography>
                                <ScrollLink
                                    to="clientes"
                                    spy={true}
                                    smooth={true}
                                    offset={-50}
                                    duration={800}
                                >
                                    <Button variant='outlined' sx={{
                                        '&:hover': {

                                            color: '#fff',
                                            bgcolor: '#000',
                                            transition: '0.2s',
                                            borderColor: '#000'
                                        },
                                        mt: 3, borderRadius: '0px', borderColor: '#000', fontSize: {xs:"14px",sm:'18px'}, color: '#000', fontWeight: 'bold', width: '270px', height: '42px'
                                    }}>VEJA NOSSOS CLIENTES</Button>
                                </ScrollLink>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={8} sx={{ mt: 3, order: { xs: 2, md: 2 } }}>

                            <CarrouselCases />


                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>

    )
}