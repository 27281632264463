import { Box } from "@mui/material"
import { motion } from "framer-motion";
export const AnimacaoIcons =()=>{
  const iconVariants = {
    float: {
      y: [-5, 5],
      transition: {
        y: {
          duration: 1,
          repeat: Infinity, 
          repeatType: "reverse", // Inverte a animação nos ciclos
          
        },
      },
    },
  };
  return(
    <Box sx={{display:{md:'flex',xs:'none'},justifyContent:'center',alignItems:'center',height:'550px'}}>
   <motion.div
      initial={{ y: -5 }}
      animate={{ y: [5, -5], transition: { duration: 1, repeat: Infinity, repeatType: 'reverse' } }}
        style={{ position: 'absolute', top: 200 }}
      >    <img src="images/iconsheader/icon1.png" style={{width:'100%',height:'100%'}}/></motion.div>
    
    <motion.div
     initial={{ y: -5 }}
     animate={{ y: [5,-5] , transition: { duration: 1, repeat: Infinity, repeatType: 'reverse' } }}
        style={{ position: 'absolute', top: 200,marginLeft:'300px' }}
      >
        <img src="images/iconsheader/icon2.png" style={{width:'100%',height:'100%'}} />
      </motion.div>
      <motion.div
       initial={{ y: -5 }}
       animate={{ y: [5,-5] , transition: { duration: 1, repeat: Infinity, repeatType: 'reverse' } }}
        style={{ position: 'absolute', top: 350,marginLeft:'350px' }}
      >
        <img src="images/iconsheader/icon3.png" style={{width:'100%',height:'100%'}} />
      </motion.div>
     
      <motion.div
        variants={iconVariants}
        initial="float"
        animate="float"
        style={{ position: 'absolute', top: 460,marginLeft:'335px' }}
      >
 <img src="images/iconsheader/icon4.png" style={{width:'100%',height:'100%'}} />
      </motion.div>

      <motion.div
        variants={iconVariants}
        initial="float"
        animate="float"
        style={{ position: 'absolute', top: 480,marginLeft:'100px' }}
      >
 <img src="images/iconsheader/icon5.png" style={{width:'100%',height:'100%'}} />
      </motion.div>
     
      <motion.div
        variants={iconVariants}
        initial="float"
        animate="float"
        style={{ position: 'absolute', top: 480,marginLeft:'-250px' }}
      >
 <img src="images/iconsheader/icon6.png" style={{width:'100%',height:'100%'}} />
      </motion.div>
    
      <motion.div
        variants={iconVariants}
        initial="float"
        animate="float"
        style={{ position: 'absolute', top: 390,marginLeft:'-320px' }}
      >
  <img src="images/iconsheader/icon7.png" style={{width:'100%',height:'100%'}} />
      </motion.div>

      <motion.div
        variants={iconVariants}
        initial="float"
        animate="float"
        style={{ position: 'absolute', top: 200,marginLeft:'-320px' }}
      >
 <img src="images/iconsheader/icon8.png" style={{width:'100%',height:'100%'}} />
      </motion.div>
    
      <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',height:250,width:250}}>
        <Box sx={{width:126,height:126,boxShadow: '0px 22px 70px 4px rgba(90, 255, 67, 0.56)',}}>
        <img src="images/iconsheader/indeias.png" style={{width:'100%',height:'100%'}}/>
        </Box>
  
      </Box>
    </Box>
  )
}