import { Box, Button, Container, Typography } from "@mui/material"
import { CarrouselClientes } from "../carrouselclientes/CarrouselClientes"
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { motion } from "framer-motion";
export const Confia = () => {
    return (
        <>
            <Box sx={{ py: 5 }}>
                <Container>


                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                        <Typography sx={{ fontSize: { xs: '32px', sm: '56px', textAlign: 'center' }, fontWeight: 'bold', lineHeight: { xs: '32px', sm: '56px' } }}>CLIENTES QUE <span style={{ color: '#5AFF43' }}>CONFIAM </span> NA GENTE<span style={{ color: '#5AFF43' }}><motion.span
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 0.1 }}
                                style={{color:'#5AFF43'}}
                                transition={{
                                    repeat: Infinity, // Repete a animação infinitamente
                                    repeatType: 'reverse', // Inverte a animação entre "initial" e "animate"
                                    duration: 0.5, // Duração de cada piscada
                                }}
                            >
                                _
                            </motion.span></span></Typography>
                        <Typography variant="roboto" sx={{ fontSize: { xs: '16px', sm: '24px' }, textAlign: 'center', lineHeight: { xs: '16px', sm: '28px' }, fontWeight: 'light' }}>Ajudamos nossos clientes a <span style={{ fontWeight: 'bold' }}>conhecer, analizar, e usar resultados</span><span style={{ color: '#5AFF43', fontWeight: 'bold' }}> aumentar a performance</span> para de seus negócios.</Typography>

                        <Box sx={{ width: '100%', mt: 2 }}>
                            <CarrouselClientes />
                        </Box>
                        <ScrollLink
                            to="vamos conversar"
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={800}
                        >
                            <Button variant="outlined" sx={{ 
                                '&:hover': {

                                    color: '#fff',
                                    bgcolor:'#000',
                                    transition:'0.2s',
                                   borderColor:'#000'
                                   
                                  },
                                mt: 2, boxShadow: 0,borderColor:'#000', borderRadius: '0px', fontSize: {xs:"14px",sm:'18px'}, fontWeight: 'bolder', color: '#000', width: { xs: '100%', lg: '350px' } }}>BORA COLOCAR SUA MARCA AQUI?</Button>
                            </ScrollLink>
                    </Box>
           


            </Container>
        </Box >
        </>
    )
}