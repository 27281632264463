import React from 'react';
import { Typography, Box } from '@mui/material';
import Marquee from 'react-fast-marquee';

export const Frases = () => {
  return (
    <Box sx={{ bgcolor: '#5AFF43' }}>
      <Marquee direction="right" speed={50} delay={0}>
        <Typography
          sx={{
            fontSize: {xs:'18px',sm:'28px'},
            fontWeight: 'bolder',
          }}
        >
          TRANSFORMANDO_SISTEMAS TRANSFORMANDO_DADOS TRANSFORMANDO_MARKETING TRANSFORMANDO_EXPERIÊNCIAS TRANSFORMANDO_MARCAS TRANSFORMANDO_COMUNICAÇÃO TRANSFORMANDO_IDEIAS TRANSFORMANDO_GESTÃO TRANSFORMANDO_NEGÓCIOS&nbsp;
        </Typography>
      </Marquee>
      <Marquee direction="left" speed={50} delay={0}>
        <Typography
          sx={{
            fontSize: {xs:'18px',sm:'28px'},
            fontWeight: 'bolder',
          }}
        >
          TRANSFORMANDO_SISTEMAS TRANSFORMANDO_DADOS TRANSFORMANDO_MARKETING TRANSFORMANDO_EXPERIÊNCIAS TRANSFORMANDO_MARCAS TRANSFORMANDO_COMUNICAÇÃO TRANSFORMANDO_IDEIAS TRANSFORMANDO_GESTÃO TRANSFORMANDO_NEGÓCIOS&nbsp;
        </Typography>
      </Marquee>
    </Box>
  );
};
