import { Box, Button, Container, Grid, Typography } from "@mui/material"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/scrollbar';
import { Scrollbar } from 'swiper/modules';

export const CarrouselLivingLean = () => {
    const breakpoints = {
        // Configuração para dispositivos móveis
        0: {
          slidesPerView: 1,
          spaceBetween:-45
        },
        375:{
            slidesPerView: 1,
            spaceBetween:-55
        },
        425:{
            slidesPerView: 1,
            spaceBetween:-65
        },
        768: {
            slidesPerView: 3,
          },
        // Configuração para desktops
        1024: {
          slidesPerView: 4,
          spaceBetween:-20
        },
        1440: {
            slidesPerView: 5,
            spaceBetween:-20
          },
      };
    return (
        <>

            <Box sx={{mt:4}}>


             
                    <Box sx={{cursor:'pointer',mt:3}}>

              
                    <Swiper
                                    scrollbar={{
                                        hide: true,
                                    }}
                                    modules={[Scrollbar]}
                                    slidesPerView={1}
                                  
                                    breakpoints={breakpoints}
                                    className="mySwiper"
                                   style={{paddingBottom:'15px',width:'100%'}}
                                >
                                <SwiperSlide>
                                <Box sx={{display:'flex',flexDirection:'column',textAlign:'center',alignItems:'center',justifyContent:'space-between', border: 1, width: '200px', height: '200px',bgcolor:'#000',border:1,borderColor:'#5AFF43', }}>
                                <img  src="images/sobrenos/icon1.svg" style={{width:'48px',height:'48px',marginTop:20}}/>
                                <Typography sx={{fontWeight:'bold',fontSize:'16px',color:'#5AFF43',mb:2}}>Mapeamento<br/> Preciso</Typography>
                                </Box>
                                </SwiperSlide>

                                <SwiperSlide>
                                <Box sx={{display:'flex',flexDirection:'column',textAlign:'center',alignItems:'center',justifyContent:'space-between', border: 1, width: '200px', height: '200px',bgcolor:'#000',border:1,borderColor:'#5AFF43', }}>
                                <img  src="images/sobrenos/icon2.svg" style={{width:'48px',height:'48px',marginTop:20}}/>
                                <Typography sx={{fontWeight:'bold',fontSize:'16px',color:'#5AFF43',mb:2}}>Priorização<br/> Estratégica</Typography>
                                </Box>
                                </SwiperSlide>

                                <SwiperSlide>
                                <Box sx={{display:'flex',flexDirection:'column',textAlign:'center',alignItems:'center',justifyContent:'space-between', border: 1, width: '200px', height: '200px',bgcolor:'#000',border:1,borderColor:'#5AFF43', }}>
                                <img  src="images/sobrenos/icon3.svg" style={{width:'48px',height:'48px',marginTop:20}}/>
                                <Typography sx={{fontWeight:'bold',fontSize:'16px',color:'#5AFF43',mb:2}}>Interação<br/> Constante</Typography>
                                </Box>
                                </SwiperSlide>

                                <SwiperSlide>
                                <Box sx={{display:'flex',flexDirection:'column',textAlign:'center',alignItems:'center',justifyContent:'space-between', border: 1, width: '200px', height: '200px',bgcolor:'#000',border:1,borderColor:'#5AFF43', }}>
                                <img  src="images/sobrenos/icon4.svg" style={{width:'48px',height:'48px',marginTop:20}}/>
                                <Typography sx={{fontWeight:'bold',fontSize:'16px',color:'#5AFF43',mb:2}}>Colaboração <br/>Contínua</Typography>
                                </Box>
                                </SwiperSlide>

                                <SwiperSlide>
                                <Box sx={{display:'flex',flexDirection:'column',textAlign:'center',alignItems:'center',justifyContent:'space-between', border: 1, width: '200px', height: '200px',bgcolor:'#000',border:1,borderColor:'#5AFF43', }}>
                                <img  src="images/sobrenos/icon5.svg" style={{width:'48px',height:'48px',marginTop:20}}/>
                                <Typography sx={{fontWeight:'bold',fontSize:'16px',color:'#5AFF43',mb:2}}>Entrega de<br/> Valor Rápida</Typography>
                                </Box>
                                </SwiperSlide>

                             
                                </Swiper>
                                </Box>
          
            </Box>
        </>
    )
}