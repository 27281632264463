import { Box, Button, Container, Grid, Typography } from "@mui/material"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/scrollbar';
import { Scrollbar } from 'swiper/modules';
export const Solucoes = () => {
    const breakpoints = {
        // Configuração para dispositivos móveis
        0: {
          slidesPerView: 1,
          spaceBetween:-45
        },
        375:{
            slidesPerView: 1,
            spaceBetween:-55
        },
        425:{
            slidesPerView: 1,
            spaceBetween:-65
        },
        768: {
            slidesPerView: 3,
          },
        // Configuração para desktops
        1024: {
            slidesPerView: 4,
            spaceBetween:-20
          },
          1440: {
              slidesPerView: 5,
              spaceBetween:-20
            },
      };
    return (
        <>

            <Box sx={{ bgcolor: '#5AFF43', py: 8 }}>


                <Container>
                    <Grid container>
                        <Grid item lg={12}>
                            <Box sx={{ display: 'flex',justifyContent:'space-between',flexDirection:{xs:'column',md:'unset'} ,alignItems:{xs:'end',lg:'center'}}}>
                            <Box sx={{width:{xs:'100%',md:'60%'},display:'flex',flexDirection:'column'}}>
                                <Box sx={{display:'flex',alignItems:'center',gap:2}}>
                                <Box sx={{width:{xs:'25px',sm:'40px'},height:{xs:'25px',sm:'40px'}}}>
                        <img src="images/iconindeiasblack.png" style={{ width: '100%', height: '100%' }} />
                        </Box>
                    
                        <Typography sx={{fontSize: { xs: '32px', sm: '56px' }, fontWeight: 'bold', lineHeight: { xs: '32px', sm: '56px' }}}>NOSSAS</Typography>
                                </Box>
                     
                        <Typography sx={{fontSize: { xs: '32px', sm: '56px' }, fontWeight: 'bold', lineHeight: { xs: '32px', sm: '56px' }}}>SOLUÇÕES</Typography>
                     
                         
                            </Box>
                            
                            <Box sx={{width:'100%',mt:2,textAlign:{xs:'start',sm:'end'}}}>
                            <Typography variant="roboto" sx={{fontSize: {xs:'16px',sm:'24px'},lineHeight:{xs:'16px',sm:'28px'},fontWeight:'light' }}>Veja onde aplicamos a <span style={{fontWeight:'bold'}}>transformação digital</span> aos nossos clientes. Para nós, o tamanho, ramo ou localidade de seu negócio não importa, mas sim entregar que você precisa.</Typography>
                            </Box>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Box sx={{cursor:'pointer',mt:3}}>

              
                    <Swiper
                                    scrollbar={{
                                        hide: true,
                                    }}
                                    modules={[Scrollbar]}
                                    slidesPerView={1}
                                  
                                    breakpoints={breakpoints}
                                    className="mySwiper"
                                   style={{paddingBottom:'15px',width:'100%'}}
                                >
                                <SwiperSlide>
                                <Box sx={{display:'flex',flexDirection:'column',textAlign:'center',alignItems:'center',justifyContent:'space-between', border: 1, width: '200px', height: '200px',bgcolor:'#000',border:1,borderColor:'#4F4F4F', }}>
                                <img  src="images/solucoes/icon1.svg" style={{width:'48px',height:'48px',marginTop:20}}/>
                                <Typography sx={{fontWeight:'bold',fontSize:'16px',color:'#5AFF43',mb:2}}>Aplicativos<br/> e Sistemas</Typography>
                                </Box>
                                </SwiperSlide>

                                <SwiperSlide>
                                <Box sx={{display:'flex',flexDirection:'column',textAlign:'center',alignItems:'center',justifyContent:'space-between', border: 1, width: '200px', height: '200px',bgcolor:'#000',border:1,borderColor:'#4F4F4F', }}>
                                <img  src="images/solucoes/icon2.svg" style={{width:'48px',height:'48px',marginTop:20}}/>
                                <Typography sx={{fontWeight:'bold',fontSize:'16px',color:'#5AFF43',mb:2}}>Websites e<br/>E-Commerce</Typography>
                                </Box>
                                </SwiperSlide>

                                <SwiperSlide>
                                <Box sx={{display:'flex',flexDirection:'column',textAlign:'center',alignItems:'center',justifyContent:'space-between', border: 1, width: '200px', height: '200px',bgcolor:'#000',border:1,borderColor:'#4F4F4F', }}>
                                <img  src="images/solucoes/icon3.svg" style={{width:'48px',height:'48px',marginTop:20}}/>
                                <Typography sx={{fontWeight:'bold',fontSize:'16px',color:'#5AFF43',mb:2}}>Gestão de<br/> Tráfego</Typography>
                                </Box>
                                </SwiperSlide>

                                <SwiperSlide>
                                <Box sx={{display:'flex',flexDirection:'column',textAlign:'center',alignItems:'center',justifyContent:'space-between', border: 1, width: '200px', height: '200px',bgcolor:'#000',border:1,borderColor:'#4F4F4F', }}>
                                <img  src="images/solucoes/icon4.svg" style={{width:'48px',height:'48px',marginTop:20}}/>
                                <Typography sx={{fontWeight:'bold',fontSize:'16px',color:'#5AFF43',mb:2}}>Experiência<br/>do Cliente</Typography>
                                </Box>
                                </SwiperSlide>

                                <SwiperSlide>
                                <Box sx={{display:'flex',flexDirection:'column',textAlign:'center',alignItems:'center',justifyContent:'space-between', border: 1, width: '200px', height: '200px',bgcolor:'#000',border:1,borderColor:'#4F4F4F', }}>
                                <img  src="images/solucoes/icon5.svg" style={{width:'48px',height:'48px',marginTop:20}}/>
                                <Typography sx={{fontWeight:'bold',fontSize:'16px',color:'#5AFF43',mb:2}}>Branding e<br/> Naming</Typography>
                                </Box>
                                </SwiperSlide>

                                <SwiperSlide>
                                <Box sx={{display:'flex',flexDirection:'column',textAlign:'center',alignItems:'center',justifyContent:'space-between', border: 1, width: '200px', height: '200px',bgcolor:'#000',border:1,borderColor:'#4F4F4F', }}>
                                <img  src="images/solucoes/icon6.svg" style={{width:'48px',height:'48px',marginTop:20}}/>
                                <Typography sx={{fontWeight:'bold',fontSize:'16px',color:'#5AFF43',mb:2}}>Copywriting <br/>e Campanhas</Typography>
                                </Box>
                                </SwiperSlide>

                                <SwiperSlide>
                                <Box sx={{display:'flex',flexDirection:'column',textAlign:'center',alignItems:'center',justifyContent:'space-between', border: 1, width: '200px', height: '200px',bgcolor:'#000',border:1,borderColor:'#4F4F4F', }}>
                                <img  src="images/solucoes/icon7.svg" style={{width:'48px',height:'48px',marginTop:20}}/>
                                <Typography sx={{fontWeight:'bold',fontSize:'16px',color:'#5AFF43',mb:2}}>Business<br/>Intelligence</Typography>
                                </Box>
                                </SwiperSlide>

                                <SwiperSlide>
                                <Box sx={{display:'flex',flexDirection:'column',textAlign:'center',alignItems:'center',justifyContent:'space-between', border: 1, width: '200px', height: '200px',bgcolor:'#000',border:1,borderColor:'#4F4F4F', }}>
                                <img  src="images/solucoes/icon8.svg" style={{width:'48px',height:'48px',marginTop:20}}/>
                                <Typography sx={{fontWeight:'bold',fontSize:'16px',color:'#5AFF43',mb:2}}>Chatbots</Typography>
                                </Box>
                                </SwiperSlide>
                                </Swiper>
                                </Box>
                </Container>
            </Box>
        </>
    )
}