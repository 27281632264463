import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Drawer, Link, SvgIcon, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { faFacebook, faInstagram, faLinkedin, faTwitter, faWhatsapp, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const NavBar = () => {

  const pages = ['Quem Somos', 'Soluções', 'Produtos', 'Cases', 'Clientes'];
  

  const [openDrawer, setOpenDrawer] =useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <AppBar position="fixed" sx={{ bgcolor: isScrolled ? 'black' : 'transparent',transition:'0.5s', boxShadow: 'none', p: 0.5 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <ScrollLink
          to="home"
          spy={true}
          smooth={true}
          duration={800}
          >
          <Box sx={{ cursor:'pointer',width: 'auto', height: 'auto', display: { xs: 'none', md: 'flex' } }}>
            <img src="/images/logo_indeias.png" style={{width:'100%',height:'100%'}}  alt="Logo" />
          </Box>
          </ScrollLink>
         
          <Box sx={{ display: { xs: 'none', md: 'block' }, border: 1, borderColor: '#fff', height: '50px' ,mx:{md:2,lg:3}}} />
          <Box
            sx={{
              width: '100%',
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              
              gap:3
            }}
          >
           
            {pages.map((page, index) => (
              <Box key={index} sx={{display:'flex'}}>
         <ScrollLink
          key={index}
          to={page.toLowerCase()}
          offset={-50}
          spy={true}
          smooth={true}
          duration={800}
        >
          <Typography sx={{
            cursor:'pointer',
        my: 2, 
        color: 'white', 
        fontSize: { md: '16px', lg: '20px' }, 
        textDecoration: 'none', 
        '&:hover': {
          color: '#5AFF43'
        },
      }}
    >{page}</Typography>
        </ScrollLink>
              </Box>
            ))}
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'block' }, border: 1, borderColor: '#fff', height: '50px', mr: 3 }} />

          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <ScrollLink
              to="vamos conversar"
              spy={true}
              smooth={true}
              duration={800}
            >
              <Button
                variant="outlined"
                sx={{
                  color: '#000',
                  width: '235px',
                  height: '45px',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  boxShadow: '0px',
                  color: '#5AFF43',
                  borderRadius: '0px',
                }}
              >
                Vamos Conversar?
              </Button>
            </ScrollLink>
          </Box>


          {/* Mobile menu */}
       <Box sx={{ display: {xs:'flex',md:'none'},justifyContent:'space-between',alignItems:'center', width: '100%' }}>
       <ScrollLink
          to="home"
          spy={true}
          smooth={true}
          duration={800}
          >

  
       <Box sx={{ width:'160px',height: '30px', display: { xs: 'flex', md: 'none' } }}>
                <img src="/images/logo_indeias.png" width={'100%'} height={'100%'} alt="Logo" />
              </Box>
              </ScrollLink>
            <Box>
            <IconButton
              onClick={() => setOpenDrawer(true)}
            >
              <MenuIcon sx={{ color: '#fff' }} />
            </IconButton> 
            </Box>
       </Box>
        </Toolbar>
        
      </Container>
    
      <Drawer
        anchor='right'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}

      >
        <Box sx={{ width:'100vw', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', bgcolor: '#000',}}>
         
       <Box sx={{ display: {xs:'flex',md:'none'},justifyContent:'space-between',alignItems:'center', width: '100%',p:2}}>
       <ScrollLink
          to="home"
          spy={true}
          smooth={true}
          duration={800}
          onClick={()=>setOpenDrawer(false)}
          >

       
       <Box sx={{width: 'auto', height: '30px', display: { xs: 'flex', md: 'none' } }}>
                <img src="/images/logo_indeias.png" width={'100%'} height={'100%'} alt="Logo" />
              </Box>
              </ScrollLink>
              <IconButton
              onClick={() =>setOpenDrawer(false)}

            >
              <CloseIcon sx={{ color: '#fff' }} />
            </IconButton> 
       </Box>
          <Container>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
              {pages.map((page, index) => (
                  <ScrollLink
                  key={index}
                  to={page.toLowerCase()}
                  offset={-50}
                  spy={true}
                  smooth={true}
                  duration={800}
                  onClick={()=>setOpenDrawer(false)}
                >

             
                <Link sx={{ color: 'white', fontSize: {xs:'18px',sm:'24px'}, textDecoration: 'none', fontWeight: 'bold' }}>
                  {page}
                </Link>
                </ScrollLink>
              ))}
            </Box>
            <Box sx={{ display: 'flex', borderTop: 1, borderColor: '#fff', width: '100%', justifyContent: 'center', mt: 3 }}>
            <ScrollLink
              to="vamos conversar"
              spy={true}
              smooth={true}
              duration={800}
              onClick={()=>setOpenDrawer(false)}
            >
        
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  color: '#000',
                  background: '#5AFF43',
                  width: '235px',
                  height: '47px',
                  fontSize:{xs:'16px',sm:'18px'},
                  fontWeight: 'bold',
                  borderRadius:'0px',
                  border:'0px',
                  boxShadow:'unset',
                  '&:hover': {

                    color: '#000',
                    bgcolor:'#fff',
                    transition:'0.2s'
                   
                  },
                }}
              >
                Vamos Conversar?
              </Button>
                    
            </ScrollLink>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 3,width:'100%'}}>
              <Typography  variant='roboto' sx={{ color: '#fff', fontSize: {xs:'18px',sm:'24px'},lineHeight:{xs:'18px',sm:'24px'} }}>Quer falar com a gente por outros meios?<br/>Sem problemas!</Typography>
              <Link variant="roboto" href="mailto:contato@indeias.com.br" sx={{ textDecoration:'none',fontWeight: 'bold', color: '#5AFF43', fontSize: {xs:'18px',sm:'28px'}, mt: 2 }}>contato@indeias.com.br</Link>
              <Box>
              <Link href="https://wa.me/5511945715167" target="_blank" sx={{ textDecoration: 'none' }}>
                                <Typography variant="roboto" sx={{ cursor: 'pointer', fontWeight: 'bold', color: '#5AFF43', fontSize: {xs:'29px',sm:'39px'} }}> <FontAwesomeIcon icon={faWhatsapp} />   (11) 94571-5167</Typography>
                            </Link>
              </Box>
              <Typography variant='roboto' sx={{ color: '#5AFF43', mt: 2, fontSize: {xs:'16px',sm:'24px'}, fontWeight: 'bolder' }}>Siga a gente em nossas redes</Typography>
              <Box sx={{ display: 'flex', gap: 2.8, }}>
                                <Link href="https://www.facebook.com/indeias/" target='_blank' sx={{ fontSize: { xs: '25px', sm: '45px' } }}>
                                    <FontAwesomeIcon icon={faFacebook} style={{ color: '#fff' }} />
                                </Link>
                                <Link href="https://www.linkedin.com/company/indeias" target='_blank' sx={{ fontSize: { xs: '25px', sm: '45px' } }}>
                                    <FontAwesomeIcon icon={faLinkedin} style={{ color: '#fff' }} />
                                </Link>
                                <Link href="https://instagram.com/indeiasoficial/" target='_blank' sx={{ fontSize: { xs: '25px', sm: '45px' } }}>
                                    <FontAwesomeIcon icon={faInstagram} style={{ color: '#fff' }} />
                                </Link>
                                <Link href="https://www.youtube.com/@indeias" target='_blank' sx={{ fontSize: { xs: '25px', sm: '45px' } }}>
                                    <FontAwesomeIcon icon={faYoutube} style={{ color: '#fff' }} />
                                </Link>
                                <Link href="https://bsky.app/profile/indeias.com.br" target="_blank" sx={{ fontSize: { xs: '25px', sm: '45px' } }}>
                                    <Box sx={{ width: { xs: '25px', sm: '45px' }, height: { xs: '25px', sm: '40px' } }}>
                                        <img src="images/footer/bsky.png" style={{ width: '100%', height: '100%' }} />
                                    </Box>
                                </Link>

                            </Box>
             
            </Box>

          </Container>
        </Box>
      </Drawer>





    </AppBar>
  );
};
